.loading-screen {
	display: flex;
	justify-content: center;
	align-items: center;
}

div.loading-screen-bubble {
	border-radius: 50%;
	width: 1rem;
	height: 1rem;
	margin: 0 5px;
	transform-origin: bottom;
}

@keyframes bubblejump {
	0% {
		transform: scale(1, 1);
	}
	25% {
		transform: scale(1.1, 0.9);
	}
	50% {
		transform: scale(0.9, 1.1) translateY(-1rem);
	}
	75% {
		transform: scale(1.1, 0.9);
	}
	100% {
		transform: scale(1, 1);
	}
}
@keyframes bubblejump2 {
	0% {
		transform: scale(1, 1);
	}

	25% {
		transform: scale(1.1, 0.9);
	}

	50% {
		transform: scale(0.9, 1.1) translateY(-1rem);
	}

	75% {
		transform: scale(1.1, 0.9);
	}

	100% {
		transform: scale(1, 1);
	}
}
