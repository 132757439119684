.drawer {
  background-color: var(--background-color, #ffffff);
  color: var(--title-color, #6a66e3);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
  /* top: 16px; */
  /* width: 330px; */
  padding: 1rem;
}

.drawer__title {
  font-weight: normal;
  font-size: 2rem;
  margin: 0;
  margin-bottom: 0.5rem;
}

.drawer__divider {
  width: 100%;
  height: 0.5rem;
  border-radius: 10px;
  background-color: var(--drawer-divider-color, #132459);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
}

.drawer > .activity-card {
  /* margin-top: 1.5rem; */
}
