code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
}

#root > div {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: "YanoneBold";
  src: local("YanoneBold"),
    url("./fonts/YanoneKaffeesatz/YanoneKaffeesatz-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "YanoneRegular";
  src: local("YanoneRegular"),
    url("./fonts/YanoneKaffeesatz/YanoneKaffeesatz-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}