.navbar {
	height: 100%;
	width: 100%;
	display: flex;
	background-color: #2197B5;
	color:  #ffffff;
	border-radius: 80px;
	padding: 0.5rem 0.6rem 0.5rem 0.6rem;
}

.navbar__inner {
	height: 100%;
	background-color: #93C6F1;
	border-radius: 100px;
	width: fill;
	width: -moz-available;
	display: flex;
	position: relative;
	justify-content: space-between;
}

.navbar__links{
	height: 100%;
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
}

.navbar__brand {
	height: 100%;
	min-width: 30%;
	width: fit-content;
}

.navbar__brand{
	padding: 5px 1rem;
}

.navbar__item{
	height: 100%;
	font-family: "Yanone Kaffeesatz", sans-serif;font-family: "YanoneRegular";
	text-transform: uppercase;
	cursor: pointer;
	display: flex;
	padding: 0.5rem 1rem;
	min-width: 20%;
}

.navbar__item img{
	display: inline-block;
	height: 100%;
}

.navbar__item h2{
	font-size: 1.5rem;
	margin: auto 0.5rem;
}

.navbar__profile {
	/* width: 48px;
  height: 48px; */
	margin: 0px 0 0 16px;
	height: 100%;
	display: flex;
}

.navbar__profile img {
	border-radius: 50%;
	height: 100%;
	max-width: none;
	border: 1px solid #3341B0;
	max-height: 70px;
}

.navbar-row {
	z-index: 110;
	height: 10%;
	width: 105% !important;
	position: relative;
	left: -2.5%;
}

.navbar-col {
	height: 100%;
}

.logout-popup {
	position: absolute;
	height: 30vh;
	width: 30vw;
	border-radius: 35px;
	top: 120%;
	right: 0;
	z-index: -10;
	width: 0;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	display: flex;
	opacity: 0;
	transition: opacity 0.2s ease, z-index 0.2s ease, width 0.2s 0.2s ease;
	background-color: #38daff;
	border: 3px solid #1f3c93;
	padding: 1rem;
	overflow: hidden;
}

.logout-popup-visible {
	width: 30vw;
	min-width: 400px;
	opacity: 1;
	z-index: 110;
	transition: opacity 0.2s ease, z-index 0.2s ease;
}

.logout-popup-button {
	padding: 0.5rem;
	border-radius: 5px;
	cursor: pointer;
	transition: 0.2s ease;
}

.logout-popup-button:hover {
	transform: scale(1.1);
	transition: 0.2s ease;
}


@media screen and (max-width: 800px){
	.navbar__item h2 {
		display: none;
	}
	
}