.navbar__mobile {
	background-color: #38daff;
	width: 100%;
	height: 10%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
