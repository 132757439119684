

.pretty-button {
	z-index: 0;
	border-radius: 14px;
	color: white;
	font-weight: lighter;
	font-family: Yanone Kaffeesatz;font-family: "YanoneRegular";
	cursor: pointer;
	transition: 0.2s ease;
    background: linear-gradient( 45deg, #0bcbff , #A969E2, #0bcbff );
    background-size: 300% 300% !important;
	border: none !important;
	text-align: center;
	margin: 0 auto;
	min-width: 150px;
	width: fit-content;
	height: fit-content !important;
	position: relative;
	overflow: hidden;
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
    transition: 0.2s ease;
	display: flex;
	justify-content: center;
}

.pretty-button img{
	height: 1.4rem;
	
}

.pretty-button-animated {
	animation: gradient-button 10s infinite;
	border: none !important;
}
/* 
.pretty-button>* {
    background-color: inherit;
	margin: 0;
	border-radius: 14px;font-size: 1.4rem;
        padding: 0.5rem 1rem;
} */

.pretty-button:hover {
    transform: translateY(-5px) !important;
    transition: 0.2s ease;
    box-shadow: 0px 10px 10px #00000040 !important;
}

/* .pretty-button:hover:after {
	content: "";
	position: absolute;
	z-index: -2;
	left: -50%;
	top: -50%;
	width: 200%;
	height: 200%;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: 50% 50%, 50% 50%;
	background-position: 0 0, 100% 0, 100% 100%, 0 100%;
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
	-webkit-animation: rotate 2s linear infinite;
	animation: rotate 2s linear infinite;
}


@-webkit-keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
} */ 

.button-disabled:hover {
	transform: none !important;
	transition: none !important;
	box-shadow: none !important;
	cursor: default !important;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.choice-button{
	font-family: Yanone Kaffeesatz;font-family: "YanoneRegular";
	font-size: 1.2rem;
	padding: 0.5rem 1rem;
	margin: 0.5rem 0.5rem 0.5rem 0;
	cursor: pointer;
	position: relative;
	transition: 0.2s ease;
	border-radius: 50px;
	overflow: hidden;
	z-index: 10;
}

.choice-button-mobile{
	font-size: 1rem;
	padding: 0.5rem 1rem;
	margin: 0;
}

.active-choice-button.choice-button-mobile{
	background-color: #7DA8DB;
}

.choice-button:not(.active-choice-button):hover{
	background: linear-gradient(to right, #c5e3ff, white);
	background-size: 300% 300%;
	animation: gradient 1s ease;
}

.active-choice-button {
	background: #177dda;
	color: white;
}