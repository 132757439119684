.mobile-landscape-container .mobile-landscape-container {
	overflow: visible;
}

.mobile-landscape-container .module-card {
	padding: 0.5rem;
}

.mobile-landscape-container .number-card {
	user-select: none;
}

.mobile-landscape-container{
	overflow: visible;
	position: relative;
}

.mobile-landscape-container .container, .mobile-landscape-container .explorer-container{
	border-radius: 0;
	height: 90%;
	padding-left: 0 !important;
	padding-right: 0 !important;
	box-shadow: none;
}

.mobile-landscape-container-ingame ~ .back-button{
	position: absolute;
}


html{
background-color: white !important;
}

.mobile-landscape-container .top-instruction{
	font: normal normal normal 2rem/2rem Yanone Kaffeesatz;font-family: "YanoneRegular";
}

.mobile-landscape-container table.icons-grid td div.back:hover {
	opacity: 1 !important;
	padding: 0;
	cursor: pointer;
}

.mobile-landscape-container .direction-button {
	position: absolute;
	width: 2.5rem;
	height: 2.5rem;
	z-index: 1000;
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	top: 40%;
	border-radius: 50%;
	transition: 0.2s ease;
	fill: white;
}

.mobile-landscape-container .direction-button svg {
	width: 100%;
}

.mobile-landscape-container .direction-button:not(button:disabled):hover {
	box-shadow: 0px 0px 10px #0000008c;
	transition: 0.2s ease;
}

.mobile-landscape-container .direction-button:disabled {
	fill: #e4e4e4;
	cursor: default;
}

.mobile-landscape-container .drawer-container {
	height: 3rem;
	overflow: hidden;
	transition: 0.5s ease;
	flex-wrap: nowrap;
	width: 100%;
	justify-content: center;
	background-color: white;
	border-radius: 0 0 10px 10px;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
}

.mobile-landscape-container .drawer-container-active {
	min-height: 85%;
	height: fit-content;
	padding-bottom: 3rem;
	overflow: visible;
}

.mobile-landscape-container  #overlay{
	height: 90vh;
	border-radius:0;
	width: 100%;
	bottom:0;
	left:0;
	top: 10%;
}


.mobile-landscape-container .drawer-container-active .drawer {
	opacity: 1;
}

.mobile-landscape-container .drawer-toggle {
	width: 2rem;
	height: 2rem;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	right: 1rem;
	z-index: 80;
	transform:rotate(-90deg);
}

.mobile-landscape-container .drawer-container:not(.drawer-container-active) .drawer-toggle{
	animation: fade-in-1 0.2s 0.5s ease both;
}

.mobile-landscape-container .drawer-toggle svg{
	width: 100%;
	height: 100%;
	color:rgb(61, 44, 64);
}

.mobile-landscape-container .drawer-container-active .drawer-toggle{
	animation: fade-in-2 0.2s 0.5s ease both;
	transform:rotate(90deg);
	bottom: -1rem;
}

.mobile-landscape-container #top-main-icon img {
	height: calc(100% - 2.5rem) !important;
}

.google-btn {
	width: auto;
	max-width: 32%;
	text-align: center;
	height: 42px;
	background-color: #4285f4;
	border-radius: 2px;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
.google-btn .google-icon-wrapper {
	position: relative;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	margin-right: 1vw;
	background-color: #ffffff;
}
.google-btn .google-icon {
	position: absolute;
	margin-top: 11px;
	margin-left: 11px;
	width: 18px;
	height: 18px;
}
.google-btn .btn-text {
	float: right;
	color: #ffffff;
	font-size: 12px;
	letter-spacing: 0.2px;
	font-family: "Acme", serif;
}
.google-btn:hover {
	box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
	background: #1669F2;
}

@keyframes fade-in-1 {
	from {
		opacity: 0;
	}

	to {
		opacity: 1
	}
}
@keyframes fade-in-2 {
	from {
		opacity: 0;
	}

	to {
		opacity: 1
	}
}

*{
	-webkit-tap-highlight-color: transparent;
}

/* @media screen and (orientation:portrait) {
	 .mobile-root:not(.mobile-login-root) {
		-ms-transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg);
		transform-origin: left top;
		left: 100%;
		position: relative;
		overflow: hidden;
	}
}

@media screen and (orientation:landscape){
	.mobile-login-root .game-description p{
		margin:0;
		font-size: 1.3rem;
	}

	.mobile-login-root .login-button{
		margin-top: 0.5rem;
	}

	.mobile-login-root .login-page-container__mobile .game-description{
		min-height: 60%;
	}
} */
