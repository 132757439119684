.tabs {
	box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.22);
	border-radius: 8px;
	padding: 16px;
	background-color: #fff;
}

.tabs__tab-bar {
	list-style: none none;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-evenly;
}

.tabs__tab-bar-item {
	font-family: Yanone Kaffeesatz, sans-serif;font-family: "YanoneRegular";
	padding: 8px;
	margin: 8px;
	font-size: 2rem;
	cursor: pointer;
	/* font-weight: bold; */
}

.tabs__tab-bar-item a {
	color: inherit;
	text-decoration: none;
}

.tabs__tab-bar-item--active {
	border-bottom: 6px solid #fe5437;
}

.tabs__tab {
	height: calc(100vh - 328px);
	padding: 68px 130px 100px;
}
