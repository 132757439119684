.back-button {
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: auto !important;
    height: 50px !important;
    border-radius: 25px;
    background-color: #7DA8DB;
    border: 3px solid #3341B0;
    box-shadow: inset 0px 5px 0px #ffffff84, inset 0px -5px 0px #00000049;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    overflow: hidden;
    cursor: pointer;
    z-index: 150;
}

.back-button>* {
    width: 44px !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-button:after {
    content: "Înapoi";
    width: 0;
    transition: 0.2s ease;
    color: white;
    font-weight: bolder;
    opacity: 0;
}

.back-button:hover:after {
    opacity: 1;
    padding: 0 10px;
    width: 100px;
    transition: 0.2s ease;
}

.back-button:hover .back-arrow{
    animation: fill-arrow 2.5s ease infinite;
}

.back-arrow{
    transform-origin: right;
}

@keyframes fill-arrow {
    0%{
        transform: scaleX(1);
    }
    10% {
        transform: scaleX(0.9);
    }
    20% {
        transform: scaleX(1.1);
    }
    30% {
        transform: scaleX(0.9);
    }
    40% {
        transform: scaleX(1);
    }
}

.mobile-landscape-container ~ .back-button{
    position: fixed;
    /* bottom: 10px; */
    top: calc(4.5% - 15px);
    left: 25px;
    width: auto !important;
    height: 30px !important;
    border-radius: 15px;
    background-color: #7DA8DB;
    border: 2px solid #3341B0;
    box-shadow: inset 0px 5px 0px #ffffff84, inset 0px -5px 0px #00000049;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    overflow: hidden;
    cursor: pointer;
}

.mobile-landscape-container ~ .back-button>* {
    width: 26px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-landscape-container ~ .back-button:after {
    content: ""
}

.mobile-landscape-container ~ .back-button:hover:after {
    width: 0 !important;
    padding: 0;
}

@media screen and (min-width: 720px) and (min-height: 540px) {

    .mobile-landscape-container-ingame~.back-button,
    .mobile-landscape-container~.back-button {
        height: 50px !important;
        width: 50px !important;
        border-radius: 50px !important;
    }
    .mobile-landscape-container-ingame~.back-button{
        top: calc(9% - 25px) !important;
    }
        .mobile-landscape-container~.back-button {
            bottom: 25px;
        }

    .mobile-landscape-container~.back-button>*,
    .mobile-landscape-container-ingame .back-button>* {
        height: 50px !important;
        width: 46px !important;
    }
}

.mobile-form-back-button{
        position: relative;
        bottom: auto;
        margin-right: 2rem ;
        top: auto;
        left: auto;
        width: auto !important;
        height: 30px !important;
        border-radius: 15px;
        background-color: #7DA8DB;
        border: 2px solid #3341B0;
        box-shadow: inset 0px 5px 0px #ffffff84, inset 0px -5px 0px #00000049;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease;
        overflow: hidden;
        cursor: pointer;
}

.mobile-form-back-button>* {
    width: 26px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-form-back-button:after {
    content: ""
}

.mobile-form-back-button:hover:after {
    width: 0 !important;
    padding: 0;
}