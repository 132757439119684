.activity-card {
	position: relative;
	border-radius: 12px;
	overflow: hidden;
	display: flex;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
	background-color: var(--background-color, #ffffff);
}

.recent-activity:hover {
	transition: 0.2s ease;
	transform: scale(1.05);
	cursor: pointer;
}
.recent-activity {
	transition: 0.2s ease;
}

.activity-card__background-svg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	height: 100%;
	width: auto;
	display: block;
	fill: var(--background-svg-color, #06bfbf);
}
.activity-card-svg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	height: 100%;
}

.activity-card__content {
	font-weight: bold;
	padding: 1rem 0 1rem 1rem;
	position: relative;
	width: 66.6666%;
}

.activity-card__title {
	color: var(--title-color, #143373);
	font-family: "Yanone Kaffeesatz", sans-serif;
	font-family: "YanoneRegular";
	font-size: 1rem;
	/* margin-top: 4px;
  margin-bottom: 8px; */
	margin: 0;
	font-weight: normal;
}

.activity-card__subtitle {
	color: var(--subtitle-color, #8d95b6);
	margin-top: 0;
	font-size: 0.7rem;
}

.activity-card__media {
	position: relative;
	width: 33.3333%;
	padding: 2%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.activity-card__media > img {
	width: 100%;
	height: auto;
}

.activity-card__score {
	background-color: var(--background-color, #ffffff);
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	display: inline-flex;
	align-items: center;
	padding: 2px 8px;
	min-width: 100px;
	position: absolute;
	bottom: 1rem;
}

.activity-card__score-icon {
	height: 1.5rem;
	width: auto;
	margin-right: 8px;
}
