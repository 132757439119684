.countdown-container {
    height: fit-content !important;
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left:0;
    overflow: hidden;
    background: linear-gradient(#F9DF5D, #f2c4f8,#3983c3);
    background:radial-gradient(ellipse, #F9DF5D, #f2c4f8, #3983c3 70%);
    background-size: 250% 250%;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.countdown-title, .clock-item-description h1{
    color: white;
    font-weight: bold;
    letter-spacing: .2rem;
    font-size: 3rem;
    text-align: center;
    z-index: 1;
    text-shadow: 1px 1px 5px #142e4572;
}

.clock-item-description h1 {
    letter-spacing: .2rem;
    font-size: 1rem;
    margin: 0;
}

.clock-item-description{
    height: fit-content
}

.days .flipUnitContainer, 
.days .flipUnitContainer .flipCard.unfold,
.days .flipUnitContainer .flipCard.fold{
    background-color: #BD3897;
}

.hours .flipUnitContainer, 
.hours .flipUnitContainer .flipCard.unfold,
.hours .flipUnitContainer .flipCard.fold{
    background-color: #45BB68;
}

.minutes .flipUnitContainer, 
.minutes .flipUnitContainer .flipCard.unfold,
.minutes .flipUnitContainer .flipCard.fold{
    background-color: #E79338;
}

.seconds .flipUnitContainer, 
.seconds .flipUnitContainer .flipCard.unfold,
.seconds .flipUnitContainer .flipCard.fold{
    background-color: #F9DF5D;
}



.flipClock {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    height: fit-content;
    min-height: 200px;
    width: 90%;
    max-width: 900px;
    z-index:2;
    position: relative;
}

.clock-item {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: auto;
    max-width: 200px;
}

.flipUnitContainer {
    display: block;
    position: relative;
    perspective-origin: 50% 50%;
    perspective: 300px;
    border-radius: 18px;
    box-shadow: 0px 10px 10px -10px grey;
    
    width: 90%;
    height: 90%;
    min-width: 50px;
    min-height: 70px;
    max-width: 80px;
    max-height: 100px;
    margin: auto;
}

.clock-item .clock-digit:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
}

.clock-item .clock-digit:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
}

.clock-item .clock-item-description {
    grid-area: 2 / 1 / 3 / 3;
}

.upperCard,
.lowerCard {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 50%;
    overflow: hidden;
    border: 1px solid whitesmoke;
}

.upperCard span,
.lowerCard span {
    font-size: 4em;
    font-weight: bold;
    color: #fff;
}

.upperCard {
    align-items: flex-end;
    border-bottom: 0.5px solid whitesmoke;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}

.upperCard span {
    transform: translateY(50%);
}

.lowerCard {
    align-items: flex-start;
    border-top: 0.5px solid whitesmoke;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
}

.lowerCard span {
    transform: translateY(-50%);
}

.flipCard {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flipCard span {
    font-size: 4em;
    font-weight: bold;
    color: #fff;
}

.flipCard.unfold {
    top: 50%;
    align-items: flex-start;
    transform-origin: 50% 0%;
    transform: rotateX(180deg);
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    border: 0.5px solid whitesmoke;
    border-top: 0.5px solid whitesmoke;
}

.flipCard.unfold span {
    transform: translateY(-50%);
}

.flipCard.fold {
    top: 0%;
    align-items: flex-end;
    transform-origin: 50% 100%;
    transform: rotateX(0deg);
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border: 0.5px solid whitesmoke;
    border-bottom: 0.5px solid whitesmoke;
}

.flipCard.fold span {
    transform: translateY(50%);
}

.fold {
    -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d;
}

.unfold {
    -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d;
}



@keyframes cloud-animation {
    0%{
        left: calc(-1.2 * max(150px, calc(100vw / 5)))
    }
    100%{
        left: calc(100vw + max(150px, calc(100vw / 5)));
    }
}

@-webkit-keyframes fold {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(-180deg);
    }
}

@keyframes fold {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(-180deg);
    }
}

@-webkit-keyframes unfold {
    0% {
        transform: rotateX(180deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes unfold {
    0% {
        transform: rotateX(180deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@media screen and (max-width: 500px) {
    .flipClock {
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }

    .clock-item{
        grid-template-rows: max-content;
    }

    .countdown-title{
        letter-spacing: .2rem;
        font-size: 2rem;
        text-align: center;
    }
}



.flipClock:before,
.flipClock:after {
    position: absolute;
    content: "";
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
}

.flipClock:before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, #fe4b2a 20%, transparent 20%),
        radial-gradient(circle, transparent 20%, #8436b0 20%, transparent 30%),
        radial-gradient(circle, #52bf04 20%, transparent 20%),
        radial-gradient(circle, #1ad1ff 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, #52bf04 15%, transparent 20%),
        radial-gradient(circle, #fe4b2a 20%, transparent 20%),
        radial-gradient(circle, #fdc701 20%, transparent 20%),
        radial-gradient(circle, #8436b0 20%, transparent 20%),
        radial-gradient(circle, #1ad1ff 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
        10% 10%, 18% 18%;
}

.flipClock:after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, #fe4b2a 20%, transparent 20%),
        radial-gradient(circle, #52bf04 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, #52bf04 15%, transparent 20%),
        radial-gradient(circle, #fdc701 20%, transparent 20%),
        radial-gradient(circle, #1ad1ff 20%, transparent 20%),
        radial-gradient(circle, #fdc701 20%, transparent 20%),
        radial-gradient(circle, #8436b0 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.flipClock.animate:before {
    display: block;
    animation: topBubbles ease-in-out 0.75s forwards;
}

.flipClock.animate:after {
    display: block;
    animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
            40% 90%, 55% 90%, 70% 90%;
    }

    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
            50% 50%, 65% 20%, 90% 30%;
    }

    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
            50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes bottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
            70% -10%, 70% 0%;
    }

    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
            105% 0%;
    }

    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
            110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}


.wiki-party{
    width: min(max(200px, 20vw), 300px);
    animation: wiki-animation-inner 4s ease-in-out infinite;
    /* transform-origin: bottom right; */
}

.wiki-party-container{
    position: absolute;
    animation: wiki-container-animation 40s ease-in infinite;
    bottom: 50px;
    left: 0;
}

@keyframes wiki-container-animation {
    0% {
        transform: translateX(-300px) translateY(0);
    }

    100% {
        transform: translateX(calc(100vw)) translateY(-100px);
    }
}


@keyframes wiki-animation-inner {
    0% {
        transform: translateY(0px)
    }
    25% {
        transform: translateY(20px)
    }
    75% {
        transform: translateY(-20px)
    }
    100% {
        transform: translateY(0px)
    }

}