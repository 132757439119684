.popup-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 151;
    left: 0;
    top:0;
    animation: fade-in-popover 0.6s both ease;
}

.form-container{
    min-height: 90%;
    position: relative;
}

.popup {
    position: absolute;
    height: 80vh;
    width: 60vw;
    max-width: 450px;
    min-width: 350px;
    background-color: white;
    border: 4px solid #177dda;
    box-shadow: 0px 12px 24px #00000040;
    border-radius: 15px;
    animation: animated-popup 0.6s both ease;
    padding: 1rem calc(2rem - 20px) 1rem 2rem;
    overflow-y: scroll; 
}

.mobile-popup{
    animation: popup-slide 0.3s both ease;
}

.big-popup {
    width: 90vw;
    max-width: 900px;
    min-width: 350px;
    padding: 2rem;
}

.big-popup button.choice-button-mobile{
    margin-top:2rem;
    border-radius: 50px;
}


.popup form{
    font-family: "Gudea", sans-serif;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
}


.mobile-popup.popup{
    height: 100%;
    width: 100%;
    min-height: 300px;
    max-width: none;
    border: none;
    border-radius: 0;
    padding: 0;
    overflow-y: hidden;
}

.mobile-popup.popup .popup-title {
    padding: 10px 0 0 0 ;
    display:none;
}




.mobile-popup .form-container, .mobile-popup .steps-container{
    /* max-height: max(50vh, 200px); */
    height:100%;
}

.mobile-popup .retragere-form{
    padding: 1rem 5rem;
}

.mobile-popup .back-button-retragere{
    top:10px !important;
    left:10px !important;
    height: 30px !important;
    width: 30px !important;
}

.mobile-popup .popup-title img{
    max-width: 150px;
    margin: auto;
    /* display: none; */
}

.mobile-popup .steps-container{
    padding:0.5rem 2rem;
    display: flex;flex-direction: column;
}

.mobile-popup .form-input-container{
    margin: 0.5rem 0;
}

.mobile-popup form{
    min-height: auto;
}

.popup .popup-title{
    margin: 0 auto;
    padding: 0.5rem 0;
    border-bottom: 1px solid #00000040;
    text-align: center;
}

div.form-input-container {
    border: 1px solid #e4e4e4;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 2px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0.5rem 0;
}

div.form-input-container input {
    outline: none;
    border: none;
    width: 100%;
    padding: 0;
}

div.form-input-container {
    font-size: 0.9rem;
    font-family: "Gudea", sans-serif;
}

div.form-input-container input::placeholder {
    text-align: left;
    color: #8d95b6;
    font-family: "Gudea", sans-serif;
}

.input-with-prefix{
    display: flex;
}

.input-with-prefix input span{
    position: relative;
}
.input-with-prefix input:invalid+span::after {
    content: "✖";
    padding-left: 5px;
    color: #8b0000;
}

.input-with-prefix input:valid+span::after {
    content: "✓";
    padding-left: 5px;
    color: #009000;
}


.popup .form-submit {
    font-family: Yanone Kaffeesatz;font-family: "YanoneRegular";
    background-color: #ff006e;
    background: #ff006e;
    cursor: pointer;
    color: white;
    height: fit-content;
    padding: 0.5em;
    border-radius: 100px;
    border: none;
    transition: 0.2s ease;
    width: 100%;
    margin: 1rem 0;
    font-size: 1rem !important;
}

.form-inputs-side-by-side{
    display: grid;
    grid-template-columns: repeat(auto-fit, minMax(300px, 1fr));
    column-gap: 1rem;
}

.form-inputs-side-by-side-4{
    display: grid;
    grid-template-columns: repeat(auto-fit, minMax(150px, 1fr));
    column-gap: 1rem;
    padding: 1rem 0;
}
.form-inputs-side-by-side-4 > *{
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.097);
    outline: none;
    border: none;
    width: 100%;
}

.form-input-container select{
    border: none;
    outline: none;
}

.popup form.form-mobile {
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.popup form.form-mobile button {
    margin-bottom: auto !important;
}
.popup form.form-mobile .form-input-container{
    margin: 0;
    width: fill;
}


@keyframes animated-popup {
    0% {
        transform: translateY(-100vh);
    }

    33% {
        transform: translateY(20vh);
    }

    66% {
        transform: translateY(-10vh);
    }

    100% {
        transform: translateY(0);
    } 
}

@keyframes fade-in-popover{
    from{
        background-color: rgba(255, 255, 255, 0);
    }
    to{
        background-color: rgba(255, 255, 255, 0.579);
    }
}

@keyframes popup-slide{
    0% {
            transform: translateX(-100vw);
        }
    
        100% {
            transform: translateX(0);
        }
}
.ant-steps {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    font-variant: tabular-nums;
    line-height: 1.66667;
    list-style: none;
    font-feature-settings: 'tnum';
    display: flex;
    width: 100%;
    font-size: 0;
    text-align: initial;
}

.ant-steps-item {
    position: relative;
    display: inline-block;
    flex: 1;
    overflow: hidden;
    vertical-align: top;
}

.ant-steps-item-container {
    outline: none;
}

.ant-steps-item:last-child {
    flex: none;
}

.ant-steps-item:last-child>.ant-steps-item-container>.ant-steps-item-tail,
.ant-steps-item:last-child>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    display: none;
}

.ant-steps-item-icon,
.ant-steps-item-content {
    display: inline-block;
    vertical-align: top;
}

.ant-steps-item-icon {
    width: 24px;
    height: 24px;
    margin: 2px 8px 2px 0;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 24px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    transition: background-color 0.3s, border-color 0.3s;
}

.ant-steps-item-icon .ant-steps-icon {
    position: relative;
    top: 0px;
    color: #7DA8DB ;
    line-height: 1;
}

.ant-steps-item-tail {
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    padding: 0 10px;
}

.ant-steps-item-tail::after {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: #f0f0f0;
    border-radius: 1px;
    transition: background 0.3s;
    content: '';
}

.ant-steps-item-title {
    position: relative;
    display: inline-block;
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 28px;
}

.ant-steps-item-title::after {
    position: absolute;
    top: 14px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: #f0f0f0;
    content: '';
}

.ant-steps-item-subtitle {
    display: inline;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: normal;
    font-size: 12px;
}

.ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}

.ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
    color: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #f0f0f0;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #f0f0f0;
}

.ant-steps-item-process .ant-steps-item-icon {
    background-color: #fff;
    border-color: #7DA8DB ;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
    color: #7DA8DB ;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #7DA8DB ;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #f0f0f0;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: rgba(0, 0, 0, 0.85);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #f0f0f0;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #7DA8DB ;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
    color: #fff;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-title {
    font-weight: 500;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #7DA8DB ;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #7DA8DB ;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #7DA8DB ;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #7DA8DB ;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #7DA8DB ;
}

.ant-steps-item-error .ant-steps-item-icon {
    background-color: #fff;
    border-color: #ff4d4f;
}

.ant-steps-item-error .ant-steps-item-icon>.ant-steps-icon {
    color: #ff4d4f;
}

.ant-steps-item-error .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #ff4d4f;
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #ff4d4f;
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #f0f0f0;
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: #ff4d4f;
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #f0f0f0;
}

.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
    background: #ff4d4f;
}

.ant-steps-item-disabled {
    cursor: not-allowed;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] {
    cursor: pointer;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
    transition: color 0.3s;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description {
    color: #7DA8DB ;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
    border-color: #7DA8DB ;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
    color: #7DA8DB ;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 16px;
    white-space: nowrap;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-left: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
    padding-right: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
    display: none;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: 140px;
    white-space: normal;
}

.ant-steps-item-custom>.ant-steps-item-container>.ant-steps-item-icon {
    height: auto;
    background: none;
    border: 0;
}

.ant-steps-item-custom>.ant-steps-item-container>.ant-steps-item-icon>.ant-steps-icon {
    top: 2px;
    left: 0.5px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
    color: #7DA8DB ;
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    width: auto;
    background: none;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 12px;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-left: 0;
}

.ant-steps-small .ant-steps-item-icon {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    border-radius: 20px;
}

.ant-steps-small .ant-steps-item-title {
    padding-right: 12px;
    font-size: 12px;
    line-height: 20px;
}

.ant-steps-small .ant-steps-item-title::after {
    top: 10px;
}

.ant-steps-small .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}

.ant-steps-small .ant-steps-item-tail {
    top: 8px;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
    width: inherit;
    height: inherit;
    line-height: inherit;
    background: none;
    border: 0;
    border-radius: 0;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
    font-size: 20px;
    line-height: 20px;
    transform: none;
}

.ant-steps-vertical {
    display: flex;
    flex-direction: column;
}

.ant-steps-vertical>.ant-steps-item {
    display: block;
    flex: 1 0 auto;
    padding-left: 0;
    overflow: visible;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
    float: left;
    margin-right: 12px;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-title {
    line-height: 24px;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-description {
    padding-bottom: 12px;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 11px;
    width: 1px;
    height: 100%;
    padding: 30px 0 6px;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
}

.ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
    display: block;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    display: none;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 9px;
    padding: 26px 0 6px;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 20px;
}

.ant-steps-label-vertical .ant-steps-item {
    overflow: visible;
}

.ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 58px;
    padding: 3.5px 24px;
}

.ant-steps-label-vertical .ant-steps-item-content {
    display: block;
    width: 108px;
    margin-top: 8px;
    text-align: center;
}

.ant-steps-label-vertical .ant-steps-item-icon {
    display: inline-block;
    margin-left: 42px;
}

.ant-steps-label-vertical .ant-steps-item-title {
    padding-right: 0;
    padding-left: 0;
}

.ant-steps-label-vertical .ant-steps-item-title::after {
    display: none;
}

.ant-steps-label-vertical .ant-steps-item-subtitle {
    display: block;
    margin-bottom: 4px;
    margin-left: 0;
    line-height: 1.66667;
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
    margin-left: 46px;
}

.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
    line-height: 1.66667;
}

.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    top: 4px;
    width: 100%;
    margin: 0 0 0 70px;
    padding: 0;
}

.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    width: calc(100% - 20px);
    height: 3px;
    margin-left: 12px;
}

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 2px;
}

.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    width: 8px;
    height: 8px;
    margin-left: 67px;
    padding-right: 0;
    line-height: 8px;
    background: transparent;
    border: 0;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    transition: all 0.3s;
    /* expand hover area */
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
    position: absolute;
    top: -12px;
    left: -26px;
    width: 60px;
    height: 32px;
    background: rgba(0, 0, 0, 0.001);
    content: '';
}

.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 140px;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    position: relative;
    top: -1px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    background: none;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
    left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin-top: 13px;
    margin-left: 0;
    background: none;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    top: 6.5px;
    left: -9px;
    margin: 0;
    padding: 22px 0 4px;
}

.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin-top: 10px;
}

.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    top: 3.5px;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
    width: inherit;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
    top: -1px;
    left: -1px;
}

.ant-steps-navigation {
    padding-top: 12px;
}

.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin-left: -12px;
}

.ant-steps-navigation .ant-steps-item {
    overflow: visible;
    text-align: center;
}

.ant-steps-navigation .ant-steps-item-container {
    display: inline-block;
    height: 100%;
    margin-left: -16px;
    padding-bottom: 12px;
    text-align: left;
    transition: opacity 0.3s;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
    max-width: auto;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    max-width: 100%;
    padding-right: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
    display: none;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
    cursor: pointer;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
    opacity: 0.85;
}

.ant-steps-navigation .ant-steps-item:last-child {
    flex: 1;
}

.ant-steps-navigation .ant-steps-item:last-child::after {
    display: none;
}

.ant-steps-navigation .ant-steps-item::after {
    position: absolute;
    top: 50%;
    left: 100%;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -14px;
    margin-left: -2px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom: none;
    border-left: none;
    transform: rotate(45deg);
    content: '';
}

.ant-steps-navigation .ant-steps-item::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: inline-block;
    width: 0;
    height: 2px;
    background-color: #7DA8DB ;
    transition: width 0.3s, left 0.3s;
    transition-timing-function: ease-out;
    content: '';
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
    left: 0;
    width: 100%;
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item {
    margin-right: 0 !important;
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item::before {
    display: none;
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item.ant-steps-item-active::before {
    top: 0;
    right: 0;
    left: unset;
    display: block;
    width: 3px;
    height: calc(100% - 24px);
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item::after {
    position: relative;
    top: -2px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-bottom: 8px;
    text-align: center;
    transform: rotate(135deg);
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    visibility: hidden;
}

.ant-steps-navigation.ant-steps-horizontal>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    visibility: hidden;
}

.ant-steps-rtl {
    direction: rtl;
}

.ant-steps.ant-steps-rtl .ant-steps-item-icon {
    margin-right: 0;
    margin-left: 8px;
}

.ant-steps-rtl .ant-steps-item-tail {
    right: 0;
    left: auto;
}

.ant-steps-rtl .ant-steps-item-title {
    padding-right: 0;
    padding-left: 16px;
}

.ant-steps-rtl .ant-steps-item-title .ant-steps-item-subtitle {
    float: left;
    margin-right: 8px;
    margin-left: 0;
}

.ant-steps-rtl .ant-steps-item-title::after {
    right: 100%;
    left: auto;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-right: 16px;
    padding-left: 0;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-right: 0;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
    padding-left: 0;
}

.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
    right: 0.5px;
    left: auto;
}

.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
    margin-right: -12px;
    margin-left: 0;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
    margin-right: -16px;
    margin-left: 0;
    text-align: right;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
    padding-left: 0;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
    right: 100%;
    left: auto;
    margin-right: -2px;
    margin-left: 0;
    transform: rotate(225deg);
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-right: 12px;
    padding-left: 0;
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-right: 0;
}

.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
    padding-right: 0;
    padding-left: 12px;
}

.ant-steps-rtl.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
    float: right;
    margin-right: 0;
    margin-left: 12px;
}

.ant-steps-rtl.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    right: 12px;
    left: auto;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    right: 10px;
    left: auto;
}

.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
    padding-left: 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin: 0 70px 0 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    margin-right: 12px;
    margin-left: 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
    right: 2px;
    left: auto;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin-right: 67px;
    margin-left: 0;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    /* expand hover area */
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    float: right;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
    right: -26px;
    left: auto;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin-right: 0;
    margin-left: 16px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    right: -9px;
    left: auto;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    right: 0;
    left: auto;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
    right: -2px;
    left: auto;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical>.ant-steps-item {
    padding-right: 4px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    right: 15px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-small.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    right: 13px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child {
    padding-right: 4px;
    padding-left: 0;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
    padding-right: 4px;
}

.ant-steps-with-progress .ant-steps-item {
    padding-top: 4px;
}

.ant-steps-with-progress .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    top: 4px;
    left: 15px;
}

.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child,
.ant-steps-with-progress.ant-steps-small.ant-steps-horizontal .ant-steps-item:first-child {
    padding-bottom: 4px;
    padding-left: 4px;
}

.ant-steps-with-progress.ant-steps-small>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    left: 13px;
}

.ant-steps-with-progress.ant-steps-vertical .ant-steps-item {
    padding-left: 4px;
}

.ant-steps-with-progress.ant-steps-label-vertical .ant-steps-item .ant-steps-item-tail {
    top: 14px !important;
}

.ant-steps-with-progress .ant-steps-item-icon {
    position: relative;
}

.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
}

.ant-btn {
    line-height: 1.66667;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 28px;
    padding: 3px 11px;
    font-size: 12px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff;
}

.ant-btn>.anticon {
    line-height: 1;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
    outline: 0;
}

.ant-btn:not([disabled]):hover {
    text-decoration: none;
}

.ant-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none;
}

.ant-btn[disabled] {
    cursor: not-allowed;
}

.ant-btn[disabled]>* {
    pointer-events: none;
}

.ant-btn-lg {
    height: 32px;
    padding: 3.3px 11px;
    font-size: 14px;
    border-radius: 2px;
}

.ant-btn-sm {
    height: 22px;
    padding: 0px 7px;
    font-size: 12px;
    border-radius: 2px;
}

.ant-btn>a:only-child {
    color: currentcolor;
}

.ant-btn>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn:hover,
.ant-btn:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: #fff;
}

.ant-btn:hover>a:only-child,
.ant-btn:focus>a:only-child {
    color: currentcolor;
}

.ant-btn:hover>a:only-child::after,
.ant-btn:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn:active {
    color: #096dd9;
    border-color: #096dd9;
    background: #fff;
}

.ant-btn:active>a:only-child {
    color: currentcolor;
}

.ant-btn:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn[disabled]>a:only-child,
.ant-btn[disabled]:hover>a:only-child,
.ant-btn[disabled]:focus>a:only-child,
.ant-btn[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn[disabled]>a:only-child::after,
.ant-btn[disabled]:hover>a:only-child::after,
.ant-btn[disabled]:focus>a:only-child::after,
.ant-btn[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
    text-decoration: none;
    background: #fff;
}

.ant-btn>span {
    display: inline-block;
}

.ant-btn-primary {
    color: #fff;
    border-color: #7DA8DB ;
    background: #7DA8DB ;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary>a:only-child {
    color: currentcolor;
}

.ant-btn-primary>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    border-color: #40a9ff;
    background: #40a9ff;
}

.ant-btn-primary:hover>a:only-child,
.ant-btn-primary:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-primary:hover>a:only-child::after,
.ant-btn-primary:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-primary:active {
    color: #fff;
    border-color: #3341B0;
    background: #3341B0;
}

.ant-btn-primary:active>a:only-child {
    color: currentcolor;
}

.ant-btn-primary:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-primary[disabled]>a:only-child,
.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-primary[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-primary[disabled]>a:only-child::after,
.ant-btn-primary[disabled]:hover>a:only-child::after,
.ant-btn-primary[disabled]:focus>a:only-child::after,
.ant-btn-primary[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-left-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary+.ant-btn-primary {
    border-left-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled] {
    border-left-color: #d9d9d9;
}

.ant-btn-ghost {
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: transparent;
}

.ant-btn-ghost>a:only-child {
    color: currentcolor;
}

.ant-btn-ghost>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent;
}

.ant-btn-ghost:hover>a:only-child,
.ant-btn-ghost:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-ghost:hover>a:only-child::after,
.ant-btn-ghost:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-ghost:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent;
}

.ant-btn-ghost:active>a:only-child {
    color: currentcolor;
}

.ant-btn-ghost:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-ghost[disabled]>a:only-child,
.ant-btn-ghost[disabled]:hover>a:only-child,
.ant-btn-ghost[disabled]:focus>a:only-child,
.ant-btn-ghost[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-ghost[disabled]>a:only-child::after,
.ant-btn-ghost[disabled]:hover>a:only-child::after,
.ant-btn-ghost[disabled]:focus>a:only-child::after,
.ant-btn-ghost[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dashed {
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff;
    border-style: dashed;
}

.ant-btn-dashed>a:only-child {
    color: currentcolor;
}

.ant-btn-dashed>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: #fff;
}

.ant-btn-dashed:hover>a:only-child,
.ant-btn-dashed:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-dashed:hover>a:only-child::after,
.ant-btn-dashed:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dashed:active {
    color: #096dd9;
    border-color: #096dd9;
    background: #fff;
}

.ant-btn-dashed:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dashed:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-dashed[disabled]>a:only-child,
.ant-btn-dashed[disabled]:hover>a:only-child,
.ant-btn-dashed[disabled]:focus>a:only-child,
.ant-btn-dashed[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dashed[disabled]>a:only-child::after,
.ant-btn-dashed[disabled]:hover>a:only-child::after,
.ant-btn-dashed[disabled]:focus>a:only-child::after,
.ant-btn-dashed[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-danger {
    color: #fff;
    border-color: #ff4d4f;
    background: #ff4d4f;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-danger>a:only-child {
    color: currentcolor;
}

.ant-btn-danger>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
    color: #fff;
    border-color: #ff7875;
    background: #ff7875;
}

.ant-btn-danger:hover>a:only-child,
.ant-btn-danger:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-danger:hover>a:only-child::after,
.ant-btn-danger:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-danger:active {
    color: #fff;
    border-color: #d9363e;
    background: #d9363e;
}

.ant-btn-danger:active>a:only-child {
    color: currentcolor;
}

.ant-btn-danger:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-danger[disabled]>a:only-child,
.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-danger[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-danger[disabled]>a:only-child::after,
.ant-btn-danger[disabled]:hover>a:only-child::after,
.ant-btn-danger[disabled]:focus>a:only-child::after,
.ant-btn-danger[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-link {
    color: #7DA8DB ;
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}

.ant-btn-link>a:only-child {
    color: currentcolor;
}

.ant-btn-link>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-link:hover,
.ant-btn-link:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent;
}

.ant-btn-link:hover>a:only-child,
.ant-btn-link:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-link:hover>a:only-child::after,
.ant-btn-link:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-link:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent;
}

.ant-btn-link:active>a:only-child {
    color: currentcolor;
}

.ant-btn-link:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-link[disabled]>a:only-child,
.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-link[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-link[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-link:hover {
    background: transparent;
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
    border-color: transparent;
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-link[disabled]>a:only-child,
.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-link[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-link[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-text {
    color: rgba(0, 0, 0, 0.85);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}

.ant-btn-text>a:only-child {
    color: currentcolor;
}

.ant-btn-text>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-text:hover,
.ant-btn-text:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent;
}

.ant-btn-text:hover>a:only-child,
.ant-btn-text:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-text:hover>a:only-child::after,
.ant-btn-text:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-text:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent;
}

.ant-btn-text:active>a:only-child {
    color: currentcolor;
}

.ant-btn-text:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-text[disabled]>a:only-child,
.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-text[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-text[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-text:hover,
.ant-btn-text:focus {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
}

.ant-btn-text:active {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-text[disabled]>a:only-child,
.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-text[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-text[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous {
    color: #ff4d4f;
    border-color: #ff4d4f;
    background: #fff;
}

.ant-btn-dangerous>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
    color: #ff7875;
    border-color: #ff7875;
    background: #fff;
}

.ant-btn-dangerous:hover>a:only-child,
.ant-btn-dangerous:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous:hover>a:only-child::after,
.ant-btn-dangerous:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous:active {
    color: #d9363e;
    border-color: #d9363e;
    background: #fff;
}

.ant-btn-dangerous:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-dangerous[disabled]>a:only-child,
.ant-btn-dangerous[disabled]:hover>a:only-child,
.ant-btn-dangerous[disabled]:focus>a:only-child,
.ant-btn-dangerous[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous[disabled]>a:only-child::after,
.ant-btn-dangerous[disabled]:hover>a:only-child::after,
.ant-btn-dangerous[disabled]:focus>a:only-child::after,
.ant-btn-dangerous[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-primary {
    color: #fff;
    border-color: #ff4d4f;
    background: #ff4d4f;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-dangerous.ant-btn-primary>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
    color: #fff;
    border-color: #ff7875;
    background: #ff7875;
}

.ant-btn-dangerous.ant-btn-primary:hover>a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-primary:active {
    color: #fff;
    border-color: #d9363e;
    background: #d9363e;
}

.ant-btn-dangerous.ant-btn-primary:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-link {
    color: #ff4d4f;
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-link:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
    color: #ff7875;
    border-color: transparent;
    background: transparent;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-link:active {
    color: #d9363e;
    border-color: transparent;
    background: transparent;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-text {
    color: #ff4d4f;
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent;
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-text:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
    color: #ff7875;
    border-color: transparent;
    background: rgba(0, 0, 0, 0.018);
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-text:active {
    color: #d9363e;
    border-color: transparent;
    background: rgba(0, 0, 0, 0.028);
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-icon-only {
    width: 28px;
    height: 28px;
    padding: 1.3px 0;
    font-size: 14px;
    border-radius: 2px;
    vertical-align: -3px;
}

.ant-btn-icon-only>* {
    font-size: 14px;
}

.ant-btn-icon-only.ant-btn-lg {
    width: 32px;
    height: 32px;
    padding: 1.7px 0;
    font-size: 16px;
    border-radius: 2px;
}

.ant-btn-icon-only.ant-btn-lg>* {
    font-size: 16px;
}

.ant-btn-icon-only.ant-btn-sm {
    width: 22px;
    height: 22px;
    padding: 0px 0;
    font-size: 12px;
    border-radius: 2px;
}

.ant-btn-icon-only.ant-btn-sm>* {
    font-size: 12px;
}

.ant-btn-icon-only>.anticon {
    display: flex;
    justify-content: center;
}

.ant-btn-icon-only .anticon-loading {
    padding: 0 !important;
}

a.ant-btn-icon-only {
    vertical-align: -1px;
}

a.ant-btn-icon-only>.anticon {
    display: inline;
}

.ant-btn-round {
    height: 28px;
    padding: 3px 14px;
    font-size: 12px;
    border-radius: 28px;
}

.ant-btn-round.ant-btn-lg {
    height: 32px;
    padding: 3.3px 16px;
    font-size: 14px;
    border-radius: 32px;
}

.ant-btn-round.ant-btn-sm {
    height: 22px;
    padding: 0px 11px;
    font-size: 12px;
    border-radius: 22px;
}

.ant-btn-round.ant-btn-icon-only {
    width: auto;
}

.ant-btn-circle {
    min-width: 28px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
}

.ant-btn-circle.ant-btn-lg {
    min-width: 32px;
    border-radius: 50%;
}

.ant-btn-circle.ant-btn-sm {
    min-width: 22px;
    border-radius: 50%;
}

.ant-btn::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: '';
    pointer-events: none;
}

.ant-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn .anticon.anticon-plus>svg,
.ant-btn .anticon.anticon-minus>svg {
    shape-rendering: optimizespeed;
}

.ant-btn.ant-btn-loading {
    position: relative;
    cursor: default;
}

.ant-btn.ant-btn-loading::before {
    display: block;
}

.ant-btn>.ant-btn-loading-icon {
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn>.ant-btn-loading-icon .anticon {
    padding-right: 4px;
    animation: none;
}

.ant-btn>.ant-btn-loading-icon .anticon svg {
    animation: loadingCircle 1s infinite linear;
}

.ant-btn-group {
    position: relative;
    display: inline-flex;
}

.ant-btn-group>.ant-btn,
.ant-btn-group>span>.ant-btn {
    position: relative;
}

.ant-btn-group>.ant-btn:hover,
.ant-btn-group>span>.ant-btn:hover,
.ant-btn-group>.ant-btn:focus,
.ant-btn-group>span>.ant-btn:focus,
.ant-btn-group>.ant-btn:active,
.ant-btn-group>span>.ant-btn:active {
    z-index: 2;
}

.ant-btn-group>.ant-btn[disabled],
.ant-btn-group>span>.ant-btn[disabled] {
    z-index: 0;
}

.ant-btn-group .ant-btn-icon-only {
    font-size: 12px;
}

.ant-btn-group .ant-btn+.ant-btn,
.ant-btn+.ant-btn-group,
.ant-btn-group span+.ant-btn,
.ant-btn-group .ant-btn+span,
.ant-btn-group>span+span,
.ant-btn-group+.ant-btn,
.ant-btn-group+.ant-btn-group {
    margin-left: -1px;
}

.ant-btn-group .ant-btn-primary+.ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent;
}

.ant-btn-group .ant-btn {
    border-radius: 0;
}

.ant-btn-group>.ant-btn:first-child,
.ant-btn-group>span:first-child>.ant-btn {
    margin-left: 0;
}

.ant-btn-group>.ant-btn:only-child {
    border-radius: 2px;
}

.ant-btn-group>span:only-child>.ant-btn {
    border-radius: 2px;
}

.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.ant-btn-group-sm>.ant-btn:only-child {
    border-radius: 2px;
}

.ant-btn-group-sm>span:only-child>.ant-btn {
    border-radius: 2px;
}

.ant-btn-group-sm>.ant-btn:first-child:not(:last-child),
.ant-btn-group-sm>span:first-child:not(:last-child)>.ant-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.ant-btn-group-sm>.ant-btn:last-child:not(:first-child),
.ant-btn-group-sm>span:last-child:not(:first-child)>.ant-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.ant-btn-group>.ant-btn-group {
    float: left;
}

.ant-btn-group>.ant-btn-group:not(:first-child):not(:last-child)>.ant-btn {
    border-radius: 0;
}

.ant-btn-group>.ant-btn-group:first-child:not(:last-child)>.ant-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-btn-group>.ant-btn-group:last-child:not(:first-child)>.ant-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ant-btn-rtl.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-rtl.ant-btn+.ant-btn-group,
.ant-btn-rtl.ant-btn-group span+.ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn+span,
.ant-btn-rtl.ant-btn-group>span+span,
.ant-btn-rtl.ant-btn-group+.ant-btn,
.ant-btn-rtl.ant-btn-group+.ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-group-rtl.ant-btn+.ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span+.ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn+span,
.ant-btn-group-rtl.ant-btn-group>span+span,
.ant-btn-group-rtl.ant-btn-group+.ant-btn,
.ant-btn-group-rtl.ant-btn-group+.ant-btn-group {
    margin-right: -1px;
    margin-left: auto;
}

.ant-btn-group.ant-btn-group-rtl {
    direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
    border-radius: 0 2px 2px 0;
}

.ant-btn-group-rtl.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
    border-radius: 2px 0 0 2px;
}

.ant-btn-group-rtl.ant-btn-group-sm>.ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm>span:first-child:not(:last-child)>.ant-btn {
    border-radius: 0 2px 2px 0;
}

.ant-btn-group-rtl.ant-btn-group-sm>.ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm>span:last-child:not(:first-child)>.ant-btn {
    border-radius: 2px 0 0 2px;
}

.ant-btn:focus>span,
.ant-btn:active>span {
    position: relative;
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
    margin-left: 4px;
}

.ant-btn.ant-btn-background-ghost {
    color: #fff;
    border-color: #fff;
}

.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
    background: transparent;
}

.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
    color: #40a9ff;
    border-color: #40a9ff;
}

.ant-btn.ant-btn-background-ghost:active {
    color: #096dd9;
    border-color: #096dd9;
}

.ant-btn.ant-btn-background-ghost[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-primary {
    color: #7DA8DB ;
    border-color: #7DA8DB ;
    text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
    color: #40a9ff;
    border-color: #40a9ff;
}

.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-primary:active {
    color: #096dd9;
    border-color: #096dd9;
}

.ant-btn-background-ghost.ant-btn-primary:active>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-danger {
    color: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
    color: #ff7875;
    border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-danger:active {
    color: #d9363e;
    border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-danger:active>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous {
    color: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
    color: #ff7875;
    border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous:active {
    color: #d9363e;
    border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
    color: #ff4d4f;
    border-color: transparent;
    text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
    color: #ff7875;
    border-color: transparent;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
    color: #d9363e;
    border-color: transparent;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
    color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

.ant-btn-two-chinese-chars::first-letter {
    letter-spacing: 0.34em;
}

.ant-btn-two-chinese-chars>*:not(.anticon) {
    margin-right: -0.34em;
    letter-spacing: 0.34em;
}

.ant-btn.ant-btn-block {
    width: 100%;
}

.ant-btn:empty {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
}

a.ant-btn {
    padding-top: 0.01px !important;
    line-height: 26px;
}

a.ant-btn-disabled {
    cursor: not-allowed;
}

a.ant-btn-disabled>* {
    pointer-events: none;
}

a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
}

a.ant-btn-disabled>a:only-child,
a.ant-btn-disabled:hover>a:only-child,
a.ant-btn-disabled:focus>a:only-child,
a.ant-btn-disabled:active>a:only-child {
    color: currentcolor;
}

a.ant-btn-disabled>a:only-child::after,
a.ant-btn-disabled:hover>a:only-child::after,
a.ant-btn-disabled:focus>a:only-child::after,
a.ant-btn-disabled:active>a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
}

a.ant-btn-lg {
    line-height: 30px;
}

a.ant-btn-sm {
    line-height: 20px;
}

.ant-btn-compact-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
    margin-right: -1px;
}

.ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-item-rtl {
    margin-left: -1px;
}

.ant-btn-compact-item:hover,
.ant-btn-compact-item:focus,
.ant-btn-compact-item:active {
    z-index: 2;
}

.ant-btn-compact-item[disabled] {
    z-index: 0;
}

.ant-btn-compact-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-last-item).ant-btn {
    border-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-first-item:not(.ant-btn-compact-last-item) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-last-item:not(.ant-btn-compact-first-item) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-btn-icon-only.ant-btn-compact-item {
    flex: none;
}

.ant-btn-compact-item.ant-btn-primary:not([disabled])+.ant-btn-compact-item.ant-btn-primary:not([disabled]) {
    position: relative;
}

.ant-btn-compact-item.ant-btn-primary:not([disabled])+.ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
    position: absolute;
    top: -1px;
    left: -1px;
    display: inline-block;
    width: 1px;
    height: calc(100% + 1px * 2);
    background-color: #40a9ff;
    content: ' ';
}

.ant-btn-compact-item-rtl.ant-btn-compact-first-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-last-item) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-compact-last-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-first-item) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-first-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-last-item) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-last-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-first-item) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-primary:not([disabled])+.ant-btn-compact-item-rtl.ant-btn-primary:not([disabled])::after {
    right: -1px;
}

.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-last-item) {
    margin-bottom: -1px;
}

.ant-btn-compact-vertical-item:hover,
.ant-btn-compact-vertical-item:focus,
.ant-btn-compact-vertical-item:active {
    z-index: 2;
}

.ant-btn-compact-vertical-item[disabled] {
    z-index: 0;
}

.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-first-item):not(.ant-btn-compact-vertical-last-item) {
    border-radius: 0;
}

.ant-btn-compact-vertical-item.ant-btn-compact-vertical-first-item:not(.ant-btn-compact-vertical-last-item) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.ant-btn-compact-vertical-item.ant-btn-compact-vertical-last-item:not(.ant-btn-compact-vertical-first-item) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled])+.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) {
    position: relative;
}

.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled])+.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled])::after {
    position: absolute;
    top: -1px;
    left: -1px;
    display: inline-block;
    width: calc(100% + 1px * 2);
    height: 1px;
    background-color: #40a9ff;
    content: ' ';
}

.ant-btn-rtl {
    direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary {
    border-right-color: #40a9ff;
    border-left-color: #d9d9d9;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled] {
    border-right-color: #d9d9d9;
    border-left-color: #40a9ff;
}

.ant-btn-rtl.ant-btn>.ant-btn-loading-icon .anticon {
    padding-right: 0;
    padding-left: 4px;
}

.ant-btn-rtl.ant-btn>.anticon+span,
.ant-btn-rtl.ant-btn>span+.anticon {
    margin-right: 8px;
    margin-left: 0;
}