.document-svg{
    overflow:visible !important;
}

.document-svg:hover .document-container{
    transform: scale(1.2);
    transition: 0.3s ease;
}

.document-container, .yellow-cloud, .blue-cloud {
    transition: 0.3s ease;
    transform-origin: center;
}

.document-svg:hover .yellow-cloud {
    transform: translateX(30px);
    transition: 0.3s ease;
}

.document-svg:hover .blue-cloud {
    transform: translateX(-20px);
    transition: 0.3s ease;
}


.document-svg:hover .arrow{
    animation: download 2s ease;
}

.writing-container rect:first-child {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s 1s linear forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes download {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}