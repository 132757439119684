html {
	font-size: 16px;
	color: #464251;
}

body {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-image: url(../assets/background.svg);
	background-position: center 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	font-family: "Poppins", sans-serif;font-family: "PoppinsRegular";
	margin: 0 !important;
	padding: 2% 5%;
}

body::after {
	content: "";
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -100;
	background-image: url(../assets/background.svg);
	background-position: bottom center;
	background-size: cover;
}

body.background-blue::after {
	content: "";
	width: 100%;
	height: 100%;
	position: fixed;
	top:0;
	left:0;
	z-index: -100;
	background-image: url(../assets/bg_abonamente.svg);
	background-position: bottom center;
	background-size: cover;
}

body{
	background: none;
}

*{
	scroll-behavior: smooth;
}

a{
	color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
	font-family: "Yanone Kaffeesatz", sans-serif;font-family: "YanoneRegular";
	font-weight: 200;
}

.button {
	font-family: "Yanone Kaffeesatz", sans-serif;font-family: "YanoneRegular";
	text-transform: uppercase;
}
.container {
	display: block;
	margin: auto;
}

.grid {
	display: flex;
	flex-wrap: wrap;
}


.grid-item {
	width: 100%;
	padding: 16px;
}

.grid-item--sm-6 {
	width: calc(50% - 2 * 16px);
}

.new-password-button {
	display: none;
}
.module-card .card {
	height: 100%;
	justify-content: space-around;
	display: flex;
	flex-direction: column;
}
.module-card {
	/* padding-left: 0 !important; */
}

.main {
	overflow-y: scroll;
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
	max-height: 100%;
	padding: 1rem 0;
}

.main::-webkit-scrollbar {
	display: none;
}

* {
	box-sizing: border-box;
}

*::before,
*::after {
	box-sizing: inherit;
}

.box {
	border-radius: 6px;
	width: 100%;
	height: 100%;
	min-height: 200px;
	background-color: rgba(158, 158, 158, 0.5);
	/* text-align: center; */
}

.box.navbar {
	background-color: rgba(127, 255, 212, 0.5);
}

.box.aside {
	background-color: rgba(255, 127, 202, 0.5);
}

.box.grid {
	background-color: rgba(127, 168, 255, 0.5);
}

.col,
[class^="col-"] {
	margin-bottom: calc(2 * var(--grid-spacing));
}

.mg-0 {
	margin: 0;
}

a {
	text-decoration: none;
}

.center {
	margin: auto;
	width: 100%;
	padding: 10px;
}

.vertical-horrizontal-center {
	margin: auto;
	width: 80%;
	padding: 10px;
}

.vertical-center {
	margin-top: auto;
	margin-bottom: auto;
}

.mt-35vh {
	margin-top: 35vh;
}

.mt-15vh {
	margin-top: 15vh;
}

.mt-12vh {
	margin-top: 12vh;
}

.mt-10vh {
	margin-top: 10vh;
}

.mt-7vh {
	margin-top: 7vh;
}

.mt-5vh {
	margin-top: 5vh;
}

.pad-0 {
	padding: 0 !important;
}

img {
	max-height: 100%;
	max-width: 100%;
	height: auto;
	width: auto;
}

.center-me {
	margin: 0 auto;
}

.marquee {
	position: relative;
	margin: 0 auto;
	white-space: nowrap;
	overflow: hidden;
	box-sizing: border-box;
	padding: 0;
	display: block;
}

@keyframes marquee {
	0% {
		transform: translate(-100%, 0);
		-webkit-transform: translateX(-100%);
	}
	100% {
		transform: translate(100%, 0);
		-webkit-transform: translateX(100%);
	}
}
.marquee div {
	position: relative;
	display: inline-block;
	text-indent: 0;
	overflow: hidden;
	-webkit-transition: 15s;
	transition: 15s;
	-webkit-animation: marquee 15s linear infinite;
	animation: marquee 15s linear infinite;
}
.background-image-circles {
	/* height: 100vh; */
	position: fixed;
	bottom: 0;
	max-height: 200%;
	left: 0;
	z-index: -2;
}

.desktop-container{
	height: 100%;
}
#score-update{
	width: 40px;
	font-family:"Yanone Kaffeesatz",		sans-serif;font-family: "YanoneRegular";
	font-size: 1.5rem;
	opacity: 0;
}

.score-update-active{
	animation: score-update-animation 1s ease both;
}



@keyframes score-update-animation{
	0%{
		opacity: 1;
		transform: translateY(0);
	}
	50%{
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(-2rem);
	}
}

.shine{
	position: relative;
}

.shine::after{
	width: 100%;
	height: 100%;
	content: '';
	animation: gradient 5s  ease infinite;
	background: linear-gradient(-45deg, transparent 45%, rgba(255, 255, 255, 0.5) 50%, transparent 55%);
	background-size: 300% 300% !important;
	position: absolute;
	display: block;
	top: 0;
	left:0;
	border-radius: inherit;
}



@keyframes gradient {
	0% {background-position: 100% 110%;
	}


	100% {background-position: 0% -10%;
	}
}

.button-navigation{
	height: 100%;
	min-height: 50px;
	/* position: absolute; */
	cursor: pointer;
}


/* 
@media screen and (max-width: 1300px) {
	html {
		font-size: 0.9em;
	}
}

@media screen and (max-width: 1100px) {
	html {
		font-size: 0.7em;
	}
} */

::-webkit-scrollbar {
	width: 20px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb:vertical {
	background: linear-gradient(#2597C1, #B0E6FA);
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:horizontal {
	background: linear-gradient(to right, #2597C1, #B0E6FA);
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:horizontal:hover {
	background: linear-gradient(to right,#397B93, #8FCBE0);
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:vertical:hover {
	background: linear-gradient(#397B93, #8FCBE0);
	background-clip: content-box;
}

.logo{
	max-height: 150px;
}

.float-on-hover {
	box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
	transition: 0.2s ease;
}

.float-on-hover:hover {
	/* transform: scale(1.05);*/
	transition: 0.2s ease;
	box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
	transform: translatey(-10px) scale(1.05);
}

@keyframes floating-animation {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}

	50% {
		box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
		transform: translatey(-5px);
	}

	100% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}
}

.ant-steps-item-title{
	font-size: 0.7rem !important;
}