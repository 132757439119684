.user-info {
	/*  */
}
.user-info-greet {
	text-align: left;
	font: normal normal normal 2rem Yanone Kaffeesatz;font-family: "YanoneRegular";
	letter-spacing: 0px;
	color: #6a66e3;
	/* border-bottom: 4px solid #132459; */
	max-width: calc(800px - 3rem);
	width: 100%;
}

.activity-scores {
	text-align: left;
	font: normal normal normal 28px/40px Yanone Kaffeesatz;font-family: "YanoneRegular";
	letter-spacing: 0px;
	color: #6a66e3;
	border-bottom: 4px solid black;
}
.user-info__avatar {
	max-height: 100%;
	border-radius: 22px;
	border: 5px solid var(--border-color, #132459);
}

.user-info__button {
	width: 40px;
	height: 40px;
	border-radius: 6px;
	border: none;
}

.profile-card__content {
	font-weight: bold;
	position: relative;
	width: 66.6666%;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1rem;
}

.user-info__button--primary {
	background-color: #6a66e3;
	color: #ffffff;
	fill: #ffffff;
}

.user-info__button--secondary {
	background-color: #133170;
	color: #ffffff;
	fill: #ffffff;
}

ul.user-tabs li {
	text-align: left;
	font: normal normal bold 16px/20px Poppins;font-family: "PoppinsRegular";
	letter-spacing: 0px;
	color: #143373;
	opacity: 1;
	display: inline;
	border-right: 2px solid black;
	background: #e4e4e4 0% 0% no-repeat padding-box;
	padding: 5px 21px 10px;
}

.user-tab.active {
	opacity: 0.7;
	border-bottom: 2px solid grey;
}

.user-tab:hover {
	filter: brightness(1.1);
	cursor: pointer;
}

.user-form-submit {
	background: #06bfbf 0% 0% no-repeat padding-box;
	border-radius: 5px;
	border: 0;

	text-align: left;
	font: normal normal bold 16px/20px Poppins;font-family: "PoppinsRegular";
	letter-spacing: 0px;
	color: #ffffff;

	padding: 2px 28px;
}

.weekly-graph-icon {
	display: block;
	margin: auto;
}

.progress-module-heading {
	text-align: center;
	font: normal normal normal 28px/36px Yanone Kaffeesatz;font-family: "YanoneRegular";
	font-size: 1.3rem;
	letter-spacing: 0px;
	color: #3d2c40;
	opacity: 1;
}
