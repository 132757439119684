.clouds-container {
    height: fit-content !important;
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    /* background: linear-gradient(#B9CDC1 70%, #D9CB84); */
    /* background: radial-gradient(ellipse,#f4ad2a 1%, #f4ad2a 3%, #E3B766 5%, #8FD3D1, #8FD3D1,white 80%); */
    background: #C6FFDD;
    /* fallback for old browsers */
    background: -webkit-radial-gradient(ellipse, #f7797ddb, #FBD786, #C6FFDD 35%);
    /* Chrome 10-25, Safari 5.1-6 */
    background: radial-gradient(ellipse, #f7797ddb, #FBD786, #C6FFDD 35%);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 400% 210%;
    background-position:  top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.clouds-container-dark {
    height: fit-content !important;
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;color: white;
    background: radial-gradient(ellipse, #F9DF5D 10%, #f2c4f8 20%, #3983c3 70%);
    background-size: 250% 250%;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.clouds-screen-content-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3983c3bd;
    /* border: 3px solid #A969E2 ; */
    box-shadow:  0px 10px 10px #7ddaff24;
    padding: 2rem;
    border-radius: 50px;
    z-index: 1;
}

.clouds img {
    opacity: 0.6;
    max-width: max(150px, calc(100vw / 5));
    position: absolute;
    left: calc(-1.2 * max(150px, calc(100vw / 5)));
    max-height: min(30vh, 300px);
}

.clouds {
    width: 110vw;
    height: 110vh;
    position: absolute;
}