.ant-popover {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    font-weight: normal;
    white-space: normal;
    text-align: left;
    cursor: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.ant-popover::after {
    position: absolute;
    background: rgba(255, 255, 255, 0.01);
    content: '';
}

.ant-popover-hidden {
    display: none;
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
    padding-bottom: 15.3137085px;
}

.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
    padding-left: 15.3137085px;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
    padding-top: 15.3137085px;
}

.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
    padding-right: 15.3137085px;
}

.ant-popover-inner {
    background-color: #fff;
    padding: 1rem;
    background-clip: padding-box;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
    border-radius: 20px;
    border: 3px solid #1f3c93;
    overflow: hidden;
    
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .ant-popover {
        /* IE10+ */
    }

    .ant-popover-inner {
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
}

.ant-popover-title {
    min-width: 177px;
    min-height: 32px;
    margin: 0;
    padding: 5px 16px 4px;
    color: #1f3c93;
    font-weight: 500;
    border-bottom: 1px solid #f0f0f0;
}

.ant-popover-inner-content {
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.85);
}

.ant-popover-message {
    position: relative;
    padding: 4px 0 12px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}

.ant-popover-message>.anticon {
    position: absolute;
    top: 8.0005px;
    color: #faad14;
    font-size: 14px;
}

.ant-popover-message-title {
    padding-left: 22px;
}

.ant-popover-buttons {
    margin-bottom: 4px;
    text-align: right;
}

.ant-popover-buttons button {
    margin-left: 8px;
}

.ant-popover-arrow {
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
}

.ant-popover-arrow-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 11.3137085px;
    height: 11.3137085px;
    margin: auto;background-color: #1f3c93;
    content: '';
    pointer-events: auto;
    border-radius: 0 0 2px;
    pointer-events: none;
}

.ant-popover-arrow-content::before {
    position: absolute;
    top: -11.3137085px;
    left: -11.3137085px;
    width: 33.9411255px;
    height: 33.9411255px;
    /* background: linear-gradient(to left, #fff 50%, #fff 50%) no-repeat -10px -10px; */
    content: '';
    -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 Z');
    clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 Z');
}

.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
    bottom: -0.6862915px;
}

.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateY(-8px) rotate(45deg);
}

.ant-popover-placement-top .ant-popover-arrow {
    left: 50%;
    transform: translateX(-50%);
}

.ant-popover-placement-topLeft .ant-popover-arrow {
    left: 16px;
}

.ant-popover-placement-topRight .ant-popover-arrow {
    right: 16px;
}

.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
    left: -0.6862915px;
}

.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(8px) rotate(135deg);
}

.ant-popover-placement-right .ant-popover-arrow {
    top: 50%;
    transform: translateY(-50%);
}

.ant-popover-placement-rightTop .ant-popover-arrow {
    top: 12px;
}

.ant-popover-placement-rightBottom .ant-popover-arrow {
    bottom: 12px;
}

.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
    top: -0.6862915px;
}

.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
    transform: translateY(8px) rotate(-135deg);
}

.ant-popover-placement-bottom .ant-popover-arrow {
    left: 50%;
    transform: translateX(-50%);
}

.ant-popover-placement-bottomLeft .ant-popover-arrow {
    left: 16px;
}

.ant-popover-placement-bottomRight .ant-popover-arrow {
    right: 16px;
}

.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
    right: -0.6862915px;
}

.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(-8px) rotate(-45deg);
}

.ant-popover-placement-left .ant-popover-arrow {
    top: 50%;
    transform: translateY(-50%);
}

.ant-popover-placement-leftTop .ant-popover-arrow {
    top: 12px;
}

.ant-popover-placement-leftBottom .ant-popover-arrow {
    bottom: 12px;
}

.ant-popover-pink .ant-popover-inner {
    background-color: #eb2f96;
}

.ant-popover-pink .ant-popover-arrow-content {
    background-color: #eb2f96;
}

.ant-popover-magenta .ant-popover-inner {
    background-color: #eb2f96;
}

.ant-popover-magenta .ant-popover-arrow-content {
    background-color: #eb2f96;
}

.ant-popover-red .ant-popover-inner {
    background-color: #f5222d;
}

.ant-popover-red .ant-popover-arrow-content {
    background-color: #f5222d;
}

.ant-popover-volcano .ant-popover-inner {
    background-color: #fa541c;
}

.ant-popover-volcano .ant-popover-arrow-content {
    background-color: #fa541c;
}

.ant-popover-orange .ant-popover-inner {
    background-color: #fa8c16;
}

.ant-popover-orange .ant-popover-arrow-content {
    background-color: #fa8c16;
}

.ant-popover-yellow .ant-popover-inner {
    background-color: #fadb14;
}

.ant-popover-yellow .ant-popover-arrow-content {
    background-color: #fadb14;
}

.ant-popover-gold .ant-popover-inner {
    background-color: #faad14;
}

.ant-popover-gold .ant-popover-arrow-content {
    background-color: #faad14;
}

.ant-popover-cyan .ant-popover-inner {
    background-color: #13c2c2;
}

.ant-popover-cyan .ant-popover-arrow-content {
    background-color: #13c2c2;
}

.ant-popover-lime .ant-popover-inner {
    background-color: #a0d911;
}

.ant-popover-lime .ant-popover-arrow-content {
    background-color: #a0d911;
}

.ant-popover-green .ant-popover-inner {
    background-color: #52c41a;
}

.ant-popover-green .ant-popover-arrow-content {
    background-color: #52c41a;
}

.ant-popover-blue .ant-popover-inner {
    background-color: #1890ff;
}

.ant-popover-blue .ant-popover-arrow-content {
    background-color: #1890ff;
}

.ant-popover-geekblue .ant-popover-inner {
    background-color: #2f54eb;
}

.ant-popover-geekblue .ant-popover-arrow-content {
    background-color: #2f54eb;
}

.ant-popover-purple .ant-popover-inner {
    background-color: #722ed1;
}

.ant-popover-purple .ant-popover-arrow-content {
    background-color: #722ed1;
}

.ant-popover-rtl {
    direction: rtl;
    text-align: right;
}

.ant-popover-rtl .ant-popover-message-title {
    padding-right: 22px;
    padding-left: 16px;
}

.ant-popover-rtl .ant-popover-buttons {
    text-align: left;
}

.ant-popover-rtl .ant-popover-buttons button {
    margin-right: 8px;
    margin-left: 0;
}








.ant-zoom-enter,
.ant-zoom-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
    -webkit-animation-name: antZoomIn;
    animation-name: antZoomIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-leave.ant-zoom-leave-active {
    -webkit-animation-name: antZoomOut;
    animation-name: antZoomOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-enter,
.ant-zoom-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
    transform: none;
}

.ant-zoom-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-enter,
.ant-zoom-big-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-big-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
    -webkit-animation-name: antZoomBigIn;
    animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-big-leave.ant-zoom-big-leave-active {
    -webkit-animation-name: antZoomBigOut;
    animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-big-enter,
.ant-zoom-big-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
    transform: none;
}

.ant-zoom-big-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-big-fast-leave {
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
    -webkit-animation-name: antZoomBigIn;
    animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
    -webkit-animation-name: antZoomBigOut;
    animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
    transform: none;
}

.ant-zoom-big-fast-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-up-enter,
.ant-zoom-up-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
    -webkit-animation-name: antZoomUpIn;
    animation-name: antZoomUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-up-leave.ant-zoom-up-leave-active {
    -webkit-animation-name: antZoomUpOut;
    animation-name: antZoomUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-up-enter,
.ant-zoom-up-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
    transform: none;
}

.ant-zoom-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-down-enter,
.ant-zoom-down-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
    -webkit-animation-name: antZoomDownIn;
    animation-name: antZoomDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-down-leave.ant-zoom-down-leave-active {
    -webkit-animation-name: antZoomDownOut;
    animation-name: antZoomDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-down-enter,
.ant-zoom-down-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
    transform: none;
}

.ant-zoom-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-left-enter,
.ant-zoom-left-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
    -webkit-animation-name: antZoomLeftIn;
    animation-name: antZoomLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-left-leave.ant-zoom-left-leave-active {
    -webkit-animation-name: antZoomLeftOut;
    animation-name: antZoomLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-left-enter,
.ant-zoom-left-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
    transform: none;
}

.ant-zoom-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-right-enter,
.ant-zoom-right-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
    -webkit-animation-name: antZoomRightIn;
    animation-name: antZoomRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.ant-zoom-right-leave.ant-zoom-right-leave-active {
    -webkit-animation-name: antZoomRightOut;
    animation-name: antZoomRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}

.ant-zoom-right-enter,
.ant-zoom-right-appear {
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
    transform: none;
}

.ant-zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}@-webkit-keyframes antZoomIn {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes antZoomIn {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes antZoomOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.2);
        opacity: 0;
    }
}

@keyframes antZoomOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.2);
        opacity: 0;
    }
}

@-webkit-keyframes antZoomBigIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes antZoomBigIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes antZoomBigOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
        opacity: 0;
    }
}

@keyframes antZoomBigOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
        opacity: 0;
    }
}

@-webkit-keyframes antZoomUpIn {
    0% {
        transform: scale(0.8);
        transform-origin: 50% 0%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 50% 0%;
    }
}

@keyframes antZoomUpIn {
    0% {
        transform: scale(0.8);
        transform-origin: 50% 0%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 50% 0%;
    }
}

@-webkit-keyframes antZoomUpOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 0%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 50% 0%;
        opacity: 0;
    }
}

@keyframes antZoomUpOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 0%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 50% 0%;
        opacity: 0;
    }
}

@-webkit-keyframes antZoomLeftIn {
    0% {
        transform: scale(0.8);
        transform-origin: 0% 50%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 0% 50%;
    }
}

@keyframes antZoomLeftIn {
    0% {
        transform: scale(0.8);
        transform-origin: 0% 50%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 0% 50%;
    }
}

@-webkit-keyframes antZoomLeftOut {
    0% {
        transform: scale(1);
        transform-origin: 0% 50%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 0% 50%;
        opacity: 0;
    }
}

@keyframes antZoomLeftOut {
    0% {
        transform: scale(1);
        transform-origin: 0% 50%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 0% 50%;
        opacity: 0;
    }
}

@-webkit-keyframes antZoomRightIn {
    0% {
        transform: scale(0.8);
        transform-origin: 100% 50%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 100% 50%;
    }
}

@keyframes antZoomRightIn {
    0% {
        transform: scale(0.8);
        transform-origin: 100% 50%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 100% 50%;
    }
}

@-webkit-keyframes antZoomRightOut {
    0% {
        transform: scale(1);
        transform-origin: 100% 50%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 100% 50%;
        opacity: 0;
    }
}

@keyframes antZoomRightOut {
    0% {
        transform: scale(1);
        transform-origin: 100% 50%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 100% 50%;
        opacity: 0;
    }
}

@-webkit-keyframes antZoomDownIn {
    0% {
        transform: scale(0.8);
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 50% 100%;
    }
}

@keyframes antZoomDownIn {
    0% {
        transform: scale(0.8);
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        transform: scale(1);
        transform-origin: 50% 100%;
    }
}

@-webkit-keyframes antZoomDownOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 100%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 50% 100%;
        opacity: 0;
    }
}

@keyframes antZoomDownOut {
    0% {
        transform: scale(1);
        transform-origin: 50% 100%;
    }

    100% {
        transform: scale(0.8);
        transform-origin: 50% 100%;
        opacity: 0;
    }
}
.ant-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    border-radius: 2px;
}

.ant-collapse>.ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
}

.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 0 0 2px 2px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    display: inline-block;
    margin-right: 12px;
    font-size: 12px;
    vertical-align: -1px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
    transition: transform 0.24s;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-extra {
    margin-left: auto;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header:focus {
    outline: none;
}

.ant-collapse>.ant-collapse-item .ant-collapse-header-collapsible-only {
    cursor: default;
}

.ant-collapse>.ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
    cursor: pointer;
}

.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
    padding-left: 12px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-right: 40px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    left: auto;
    margin: 0;
    transform: translateY(-50%);
}

.ant-collapse-content {
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
}

.ant-collapse-content>.ant-collapse-content-box {
    padding: 16px;
}

.ant-collapse-content-hidden {
    display: none;
}

.ant-collapse-item:last-child>.ant-collapse-content {
    border-radius: 0 0 2px 2px;
}

.ant-collapse-borderless {
    background-color: #fafafa;
    border: 0;
}

.ant-collapse-borderless>.ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
}

.ant-collapse-borderless>.ant-collapse-item:last-child,
.ant-collapse-borderless>.ant-collapse-item:last-child .ant-collapse-header {
    border-radius: 0;
}

.ant-collapse-borderless>.ant-collapse-item:last-child {
    border-bottom: 0;
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
    background-color: transparent;
    border-top: 0;
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-top: 4px;
}

.ant-collapse-ghost {
    background-color: transparent;
    border: 0;
}

.ant-collapse-ghost>.ant-collapse-item {
    border-bottom: 0;
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content {
    background-color: transparent;
    border-top: 0;
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-top: 12px;
    padding-bottom: 12px;
}

.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header>.arrow {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.ant-collapse-rtl {
    direction: rtl;
}

.ant-collapse-rtl .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 12px 16px;
    padding-right: 40px;
}

.ant-collapse-rtl.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    margin-right: 0;
    margin-left: 12px;
}

.ant-collapse-rtl.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
    transform: rotate(180deg);
}

.ant-collapse-rtl.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-extra {
    margin-right: auto;
    margin-left: 0;
}

.ant-collapse-rtl.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
    padding-right: 12px;
    padding-left: 0;
}