.custom-button > *{
    margin: 0;
    font-size: 1.5rem;
}

.custom-button{
    padding: 1rem 2rem;
    background-color: #0BCBFF;
    border: 3px solid #177dda;
    border-radius: 24px;
    min-width: 200px;
    width: fit-content;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: .2rem;
    box-shadow: inset 0px 10px 0px #ffffff84, inset 0px -10px 0px #00000049;
}

.custom-button > div{
    content: "wow";
    width: 1rem;
    height: 1rem;
    transform: skew(-.312rad);
    position: absolute;
    top: 0.25rem;
    left: 0.5rem;
    background-color: #ffffffaf;
    border-radius: 50%;
}